import React, {useState, createContext} from "react";
import AUpageAlert from "@gov.au/page-alerts"
import Button from "@sportaus-digital/buttons";

export const GlobalAlertContext = createContext({});
export const GlobalAlertActionsContext = createContext({});

const GlobalAlertContextProvider = ({children}) => {
    const [globalAlert, setGlobalAlert] = useState({
        severity: undefined, // 'info', 'success', 'warning', 'error'
        message: undefined
    });

    return <GlobalAlertContext.Provider value={globalAlert}>
        <GlobalAlertActionsContext.Provider value={setGlobalAlert}>
            {
                globalAlert && globalAlert.message &&
                    <div className="row">
                        <div className="col-xs-12">
                            <AUpageAlert as={globalAlert.severity || "error"} className="sac-global-error">
                                {globalAlert.message}
                                {
                                    globalAlert.action && <Button onClick={globalAlert.action.onClick} as="tertiary" className="pl-0">{globalAlert.action.text}.</Button>
                                }
                            </AUpageAlert>
                        </div>
                    </div>
            }
            {children}
        </GlobalAlertActionsContext.Provider>
    </GlobalAlertContext.Provider>
};

export default GlobalAlertContextProvider;