import React, {useContext} from "react"
import {AuthActionsContext, AuthContext} from "./auth-context";
import {useKeycloak} from "@react-keycloak/web";
import uri from "urijs";

/**
 * A custom hook providing utility functionality for checking authorisation.
 *
 */
const useAuth = () => {
    const userProfile = useContext(AuthContext);
    const setUserProfile = useContext(AuthActionsContext);
    const isAdmin = userProfile && userProfile.groups && userProfile.groups.indexOf("sac-admin") > -1;

    return {
        isAdmin: isAdmin,
        authenticated: userProfile && userProfile.authenticated,
        userProfile: userProfile,
        updateUserProfile: setUserProfile
    };
};

const Protected = ({children}) => {
    const {authenticated} = useAuth();
    const {keycloak, initialized} = useKeycloak();

    if (!authenticated && keycloak && initialized) {

        const query = uri(window.location.href).search(true);
        window.SCOPE = query.scope != null ? "openid " + query.scope : "openid";

        const KeycloakLoginOptions = {
            scope: window.SCOPE
        }

        keycloak.login(KeycloakLoginOptions); // triggers a redirect
        return null;
    } else {
        return <>{children}</>;
    }
};

const ProtectedPage = ({action, orgId, children}) => {
    return <Protected action={action}
                      orgId={orgId}
                      loginIfNotAuthenticated={true}
                      showNotAuthorised={true}>
        {children}
    </Protected>
};

const withAuth = (Component) => (props) => {
    return <ProtectedPage>
        <Component {...props}/>
    </ProtectedPage>
};

export {Protected,ProtectedPage, useAuth, withAuth};
