import React, {useEffect, useState} from "react"
import {useAuth} from "../security/authorisation";
import Accordion from "@sportaus-digital/accordion";
import keycloak from "../security/keycloak";

const UserInfo = () => {
    const {userProfile} = useAuth();
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        keycloak.loadUserInfo().then((u) => setUserInfo(u));
    }, [setUserInfo]);

    return <div className="row text-left ml-5 mr-5 mt-5">
        <div className="col-sm-12">
            <hr/>
        </div>
        <div className="col-lg-6 mb-5">
            <h2>User Profile</h2>
            <Accordion expandLink="Show User Profile"
                          collapseLink="Hide User Profile">
                <pre id="userProfileToken">
            {JSON.stringify(userProfile, undefined, 2)}
            </pre>
            </Accordion>
        </div>

        <div className="col-lg-6">
            <h2>User Info Endpoint</h2>
            <Accordion expandLink="Show User Info"
                          collapseLink="Hide User Info">
                <pre id="userInfoToken">
            {JSON.stringify(userInfo, undefined, 2)}
            </pre>
            </Accordion>
        </div>
    </div>;
};

UserInfo.propTypes = {};

export default UserInfo;