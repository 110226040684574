import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import RouteScrollTo from "@sportaus-digital/route-scroll-to";
import {Route, Switch} from "react-router";
import PageNotFound from "./views/page-not-found";
import Home from "./views/home";
import Sport from "./views/sport";
import Header from "./views/header";

function App() {
  return (
      <main className="au-grid container-fixed-width">
        <a className="sr-only sr-only-focusable skippy" href="#content">Skip to main content</a>
        <div className="container-fluid">
          <Router>
            <RouteScrollTo/>
            <Header/>

            <div className="sds-content" id="content" >
                <div className="row">
                    <div className="col-sm-12 text-center mt-4">
                        <h1>Welcome to the Sport Connect demo site</h1>
                    </div>
                </div>

                <Switch>
                    <Route exact path="/"><Home/></Route>
                    <Route path="/sport"><Sport/></Route>
                    <Route component={PageNotFound}/>
                </Switch>
            </div>

          </Router>
        </div>
      </main>
  );
}

export default App;
