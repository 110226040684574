import React from "react"
import {capitalize} from "lodash";
import {withAuth} from "../security/authorisation";
import UserInfo from "./user-info";

const Sport = () => {
    return <div className="row">
        <div className="col-sm-12 text-center mt-5">
            <h2>You have logged in to <em>{window.envConfig.sport[window.SPORT].name || capitalize(window.SPORT)}</em></h2>

            <UserInfo/>
        </div>
    </div>;
};

Sport.propTypes = {};

export default withAuth(Sport);