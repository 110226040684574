import React from "react";
import {capitalize, keys, sortBy} from "lodash";

const Home = () => {
    let sports = sortBy(keys(window.envConfig.sport));

    function updateScope(event) {
        var links = [].slice.call(document.getElementsByClassName("sport-links")[0].children);
        links.forEach(child => 
            child.href = child.href.split("&scope=")[0] + "&scope=" + encodeURIComponent(event.target.value)
        );
    }

    return <><div className="row">
        <div className="col-sm-12 sport-links">
            {sports.map(s => <a href={`/sport?sport=${s}`}
                key={s}
                className="sport-link">
                {window.envConfig.sport[s].name || capitalize(s)}
            </a>)}
        </div>
    </div>
    <div className="m-4 d-flex justify-content-center">
        <label className="m-2 font-weight-bold h3">Optional Scopes (space separated)</label>
        <input className="form-control scopes" type="text" id="scope" name="scope" onChange={updateScope}></input> 
    </div></>;
};

Home.propTypes = {};

export default Home;