import React, {useContext} from "react"
import {AuthContext} from "../security/auth-context";
import UserMenu from "../security/user-menu";

const Header = () => {
    const userProfile = useContext(AuthContext);

    return <div className="header">
        <asc-header useCustomSubbrand>
            <div className="user-menu" slot="end">
                {
                    userProfile && !!userProfile.authenticated &&
                    <UserMenu/>
                }
            </div>
            <asc-sportconnect-logo slot="subbrand"></asc-sportconnect-logo>
        </asc-header>
    </div>;
};

Header.propTypes = {};

export default Header;