import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalAlertContextProvider, {GlobalAlertActionsContext} from "./utils/global-alerts";
import AuthContextProvider, {AuthActionsContext, AuthContext} from "./security/auth-context";
import {KeycloakProvider} from "@react-keycloak/web";
import keycloak from "./security/keycloak";
import SDSfullPageLoader from "./components/full-page-loader/full-page-loader"
import "./assets/scss/global.scss";
import logo from "./assets/img/sportconnect_mono.svg"

const Root = () => {
    const setGlobalAlert = useContext(GlobalAlertActionsContext);
    const setUserProfile = useContext(AuthActionsContext);
    const userProfile = useContext(AuthContext);

    const timeoutHandler = () => {
        if (!keycloak.initialized) {
            setGlobalAlert({message: "Unable to contact the authentication server. Some application features may not be available."});
            setUserProfile({keycloakFailed: true});
        }
    };

    const authEventHandler = (event) => {
        if (event === "onAuthSuccess") {
            const profile = {
                authenticated: true,
                username: keycloak.tokenParsed.preferred_username,
                token: keycloak.tokenParsed,
                idToken: keycloak.idTokenParsed
            };

            setUserProfile(profile);
        } else if (event === "onAuthRefreshError") {
            keycloak.logout({redirectUri: window.location.origin}).then(() => {
                setUserProfile({});
            });
        }
    };

    const loader = <SDSfullPageLoader timeoutHandler={timeoutHandler}
                                      logo={logo}
                                      logoAlt="Sport Connect logo"/>;

    return userProfile.keycloakFailed ?
        <App/> :
        <KeycloakProvider keycloak={keycloak}
                          onEvent={authEventHandler}
                          LoadingComponent={loader}>
            <App/>
        </KeycloakProvider>;
};


ReactDOM.render(<GlobalAlertContextProvider>
    <AuthContextProvider>
        <Root/>
    </AuthContextProvider>
</GlobalAlertContextProvider>, document.getElementById('root'));