import Keycloak from "keycloak-js";
import uri from "urijs";

const query = uri(window.location.href).search(true);
window.SPORT = query.sport || "default";
const realm = query.realm || undefined;
const client = query.client || undefined;
console.log("Sport = " + window.SPORT);

let conf = window.envConfig || {};
const kcConfig = {
    url: conf.sport[window.SPORT].keycloakAuthUrl || conf.keycloakAuthUrl || "",
    realm: realm || conf.sport[window.SPORT].keycloakRealm || "unknown",
    clientId: client || conf.sport[window.SPORT].keycloakClientId || "unknown",
    onLoad: "check-sso"
};

console.log("Keycloak Config:");
console.log(JSON.stringify(kcConfig, undefined, 2));

const keycloak = new Keycloak(kcConfig);

export default keycloak;